<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-8 col-md-8 offset-lg-2 offset-md-2">

                    <div class="d-flex">
                        <div class="text-left p-4 pb-2 text-center">
                            <h3 class="text-uppercase text-orange fw-bold mb-1 mt-2" style="letter-spacing: 1px">Account Verified</h3>
                            
                            <p style="font-size: 1.1rem">We've verified your account! You can now enjoy all the features GHS has to offer you. </p>                                

                            <router-link class="btn bg-orange text-white mt-3" :to="{name: 'Home'}">
                                <span class="fw-bold">Start your journey</span>
                                <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                            </router-link>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'DeleteUser'
    }
</script>